<template>
    <div>
        <el-dialog title="开户信息" :visible.sync="dialogPersonalDetailFormVisible" :before-close="close">
            <div slot="title" class="dislog_header">
                <span>开户信息</span>
                <span class="account_state">{{childForm.stateName}}</span>
                <span class="fail-reason">{{childForm.failReason}}</span>
            </div>
            <el-form :model="childForm" label-position="right">
                <el-form-item label="姓名" :label-width="formLabelWidth">
                    <el-input size="small" v-model="childForm.userName" :disabled="true" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="身份证号" :label-width="formLabelWidth">
                    <el-input size="small" v-model="childForm.credentialNo" :disabled="true" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机号" :label-width="formLabelWidth">
                    <el-input size="small" v-model="childForm.principalMobile" :disabled="true" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="开户地址" :label-width="formLabelWidth">
                    <el-input size="small" v-model="childForm.idNo" :disabled="true" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="银行账号" :label-width="formLabelWidth">
                    <el-input size="small" v-model="childForm.bankAccountNo" :disabled="true" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="开户银行" :label-width="formLabelWidth">
                    <el-input size="small" v-model="childForm.branchName" :disabled="true" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { openAccountDetail } from '@/request/api'
export default {
    props: {
        dialogPersonalDetailFormVisible: { type: Boolean, default: false },
        id: { type: Number, default: null }
    },
    data() {
        return {
            formLabelWidth: '140px',
            childForm: {}
        }
    },
    watch: {
        id(newVue) {
            this.queryDetail(newVue)
        }
    },
    methods: {
        async queryDetail(newVue) {
            try {
                const p = { id: newVue }
                const res = await openAccountDetail(p)
                if (res.status === 200 && res.data) {
                    this.childForm = {...res.data}
                } else {
                    this.$message.error(res.message)
                }
            } catch (error) {
                console.log(error);
            }
        },
        close() {
            this.$emit('update:dialogPersonalDetailFormVisible', false)
        }
    }
}
</script>

<style lang="stylus" scoped>
.dislog_header {
    position: relative;
    height: 80px;
}
.account_state {
    position: absolute;
    right: 0;
    bottom: 30px;
}
.fail-reason {
    position: absolute;
    right: 0;
    bottom: 0;
}
/deep/.el-dialog__body {
    padding-top: 10px;
}
</style>

