<template>
  <div style="padding-top:10px;">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane
        v-for="(item, index) in tabList"
        :key="index"
        :label="item.label"
        :name="item.name"
      >
        <div class="header">
          <el-row type="flex" justify="space-between" :gutter="20">
            <el-col :span="6">
              <div class="grid-content">
                <span class="left">用户名称</span>
                <el-input
                  class="right"
                  size="small"
                  v-model.trim="accountName"
                  placeholder="请输入用户名称"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content">
                <span class="left">证件号码</span>
                <el-input
                  class="right"
                  size="small"
                  v-model.trim="credentialNo"
                  placeholder="请输入"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content">
                <span class="left">开户状态</span>
                <el-select
                  class="right"
                  size="small"
                  v-model="accountStateName"
                  placeholder="请选择"
                  @change="selectAccountStateRes"
                >
                  <el-option
                    v-for="item in accountStateList"
                    :key="item.state"
                    :label="item.accountStateName"
                    :value="item.state"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content">
                <span class="left">绑卡状态</span>
                <el-select
                  class="right"
                  size="small"
                  v-model="bindCardStateName"
                  placeholder="请选择"
                  @change="bindCardStateRes"
                >
                  <el-option
                    v-for="item in bindCardStateList"
                    :key="item.state"
                    :label="item.bindCardStateName"
                    :value="item.state"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between" :gutter="20">
            <el-col :span="6">
              <div class="grid-content">
                <span class="left">验证状态</span>
                <el-select
                  class="right"
                  size="small"
                  v-model="verifyStateName"
                  placeholder="请选择"
                  @change="verifyStateRes"
                >
                  <el-option
                    v-for="item in verifyStateList"
                    :key="item.state"
                    :label="item.verifyStateName"
                    :value="item.state"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content">
                <span class="left">所属平台</span>
                <el-select
                  class="right"
                  v-model="isvId"
                  size="small"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入所属平台"
                  :remote-method="remoteMethod"
                  :loading="inputLoading"
                >
                  <el-option
                    v-for="item in platList"
                    :key="item.isvId"
                    :label="item.isvName"
                    :value="item.isvId"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content">
                <span class="left">渠道</span>
                <el-select
                  class="right"
                  size="small"
                  v-model="channelName"
                  placeholder="请选择"
                  @change="channelRes"
                >
                  <el-option
                    v-for="item in channelList"
                    :key="item.channel"
                    :label="item.channelName"
                    :value="item.channel"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content">
                <span class="left">提交开户时间</span>
                <el-date-picker
                  class="right"
                  v-model="dateResult"
                  type="daterange"
                  size="small"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
            </el-col>
          </el-row>
          <el-row type="flex" justify="end">
            <el-col :span="6">
              <div class="btn-content">
                <el-button type="primary" size="medium" @click="handleQuery"
                  >查询</el-button
                >
                <el-button size="medium" @click="handleClear">清空</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
        <cesTable
          :isButton="true"
          :isSelection="false"
          :isIndex="true"
          :isPagination="true"
          :tableData="tableData"
          :tableCols="tableCols"
          :pagination="pagination"
          :key="keyDate"
          :loading="loading"
          @handleDetail="handleDetail"
          @handleCurrentChange="handleCurrentChange"
          @handleSizeChange="handleSizeChange"
        ></cesTable>
      </el-tab-pane>
    </el-tabs>
    <enterpriseDetail
      :dialogEnterpriseDetailFormVisible.sync="
        dialogEnterpriseDetailFormVisible
      "
      :id="detailId"
    ></enterpriseDetail>
    <personalDetail
      :dialogPersonalDetailFormVisible.sync="dialogPersonalDetailFormVisible"
      :id="detailId"
    ></personalDetail>
  </div>
</template>

<script>
import cesTable from '@/components/table'
import { openManageList, platManageList } from '@/request/api'
import { CHANNELLIST } from '@/assets/js/config'
import enterpriseDetail from './components/enterpriseDetail'
import personalDetail from './components/personalOpenAccountDetail'
export default {
  components: {
    cesTable,
    enterpriseDetail,
    personalDetail,
  },
  data() {
    return {
      platList: [],
      tabList: [
        { label: '全部', name: 'all' },
        { label: '企业', name: 'enterprise' },
        { label: '个人', name: 'personal' },
      ],
      dialogEnterpriseDetailFormVisible: false,
      dialogPersonalDetailFormVisible: false,
      accountName: '',
      credentialNo: '',
      accountState: '',
      // 0:开户中，1：已开户；2：开户失败；3：已禁用
      accountStateList: [
        { accountStateName: '开户中', state: 0 },
        { accountStateName: '已开启', state: 1 },
        { accountStateName: '开户失败', state: 2 },
        { accountStateName: '已禁用', state: 3 },
      ],
      accountStateName: '',
      bindCardState: '',
      bindCardStateName: '',
      bindCardStateList: [
        { bindCardStateName: '未绑卡', state: 1 },
        { bindCardStateName: '已绑卡', state: 2 },
      ],
      verifyState: '',
      verifyStateName: '',
      verifyStateList: [
        { verifyStateName: '未验证', state: '1' },
        { verifyStateName: '已验证', state: '3' },
      ],
      isvId: '',
      channel: '',
      channelName: '',
      channelList: CHANNELLIST,
      submitTimeBegin: '',
      submitTimeEnd: '',
      dateResult: '',
      activeName: 'all',
      tableData: [],
      keyDate: '',
      userType: '',
      detailId: null,
      tableCols: [
        { label: '用户名称', prop: 'accountName' },
        { label: '证件号码', prop: 'credentialNo' },
        { label: '所属平台', prop: 'isvName' },
        { label: '渠道', prop: 'institutionCodeName' },
        { label: '账户类型', prop: 'subtypeName' },
        { label: '开户状态', prop: 'stateName' },
        { label: '绑卡状态', prop: 'bindAccountState' },
        { label: '验证状态', prop: 'bindAccountValidState' },
        { label: '失败原因', prop: 'failReason' },
        {
          label: '开户信息',
          prop: 'openAccount',
          type: 'Detail',
          special: '详情',
          permission: '/account/detail',
        },
        { label: '提交开户时间', prop: 'submitTime' },
        { label: '账户生效时间', prop: 'usableTime' },
      ],
      pagination: {
        pageSize: 50,
        pageNum: 1,
        total: 0,
      },
      loading: false,
      inputLoading: false,
    }
  },
  created() {
    this.queryList()
  },
  methods: {
    async remoteMethod(query) {
      try {
        const p = {
          page: {
            current: 1,
            size: 20,
          },
          isvName: query,
        }
        this.inputLoading = true
        const res = await platManageList(p)
        this.inputLoading = false
        if (res.status === 200 && res.data) {
          this.platList = res.data.items || []
        }
      } catch (error) {
        console.log(error)
      }
    },
    async queryList() {
      const date1 = this.dateResult && this.dateResult[0]
      const date2 = this.dateResult && this.dateResult[1]
      const beginDate = date1 ? date1 + ' 00:00:00' : ''
      const endDate = date2 ? date2 + ' 23:59:59' : ''
      const p = {
        page: {
          current: this.pagination.pageNum,
          size: this.pagination.pageSize,
        },
        userType: this.userType,
        accountName: this.accountName,
        credentialNo: this.credentialNo,
        accountState: this.accountState,
        bindCardState: this.bindCardState,
        verifyState: this.verifyState,
        isvId: this.isvId,
        channel: this.channel,
        submitTimeBegin: beginDate,
        submitTimeEnd: endDate,
      }

      try {
        this.loading = true
        const res = await openManageList(p)
        this.loading = false
        if (res.status == 200 && res.data) {
          this.tableData = res.data.items
          if (res.data.total && p.page.current === 1) {
            this.pagination.total = res.data.total
          }
        } else {
          this.$message.error(res.message)
          this.tableData = []
          this.pagination.total = 0
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    handleClick() {
      this.keyDate = new Date().getTime()
      switch (this.activeName) {
        case 'all':
          // 全部
          this.userType = ''
          this.handleClear()
          this.queryList()
          break
        case 'enterprise':
          // 企业
          this.userType = 3
          this.handleClear()
          this.queryList()
          break
        case 'personal':
          // 个人
          this.userType = 1
          this.handleClear()
          this.queryList()
          break
        default:
          break
      }
    },
    handleClear() {
      this.accountName = ''
      this.accountState = ''
      this.accountStateName = ''
      this.bindCardStateName = ''
      this.bindCardState = ''
      this.verifyStateName = ''
      this.verifyState = ''
      this.isvId = ''
      this.channelName = ''
      this.channel = ''
      this.dateResult = ''
      this.credentialNo = ''
    },
    handleDetail(row) {
      const id = row.id
      switch (row.userType) {
        case 1:
          // 自然人
          this.detailId = id
          this.dialogPersonalDetailFormVisible = true
          break
        case 3:
          // 企业
          this.detailId = id
          this.dialogEnterpriseDetailFormVisible = true
          break
        default:
          break
      }
    },
    handleCurrentChange(val) {
      this.pagination.pageNum = val
      this.queryList()
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val
      this.queryList()
    },
    selectAccountStateRes(state) {
      this.accountState = state
    },
    bindCardStateRes(state) {
      this.bindCardState = state
    },
    verifyStateRes(state) {
      this.verifyState = state
    },
    channelRes(channel) {
      this.channel = channel
    },
    handleQuery() {
      this.pagination.pageNum = 1
      this.queryList()
    },
  },
}
</script>

<style lang="stylus" scoped>
/deep/.el-tabs__item {
    width: 120px;
    text-align: center;
}

.header {
    padding:0 20px 10px 20px;
}

.el-row {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}

.grid-content {
    display: flex;
    align-items: center;
}

.left {
    margin-right: 20px;
    font-size: 14px;
}

.right {
    flex: 1;
}

.btn-content {
    display: flex;
    justify-content: flex-end;
}
</style>
