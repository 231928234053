<template>
    <div>
        <el-dialog title="开户信息" :visible.sync="dialogEnterpriseDetailFormVisible" :before-close="close">
            <div slot="title" class="dislog_header">
                <span>开户信息</span>
                <span class="account_state">{{childForm.stateName}}</span>
                <span class="fail-reason">{{childForm.failReason}}</span>
            </div>
            <el-form :model="childForm" label-position="right">
                <el-form-item class="first_item" label="企业名称" :label-width="formLabelWidth">
                    <el-input size="small" v-model="childForm.isvName" :disabled="true" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="企业简称" :label-width="formLabelWidth">
                    <el-input size="small" v-model="childForm.shortName" :disabled="true" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="营业执照号码" :label-width="formLabelWidth">
                    <el-input size="small" v-model="childForm.credentialNo" :disabled="true" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="企业注册所在区域" :label-width="formLabelWidth">
                    <el-input size="small" v-model="childForm.shortAddress" :disabled="true" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="企业注册详细地址" :label-width="formLabelWidth">
                    <el-input size="small" v-model="childForm.address" :disabled="true" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="法人代表名称" :label-width="formLabelWidth">
                    <el-input size="small" v-model="childForm.legalPerson" :disabled="true" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="法人代表身份证号码" :label-width="formLabelWidth">
                    <el-input size="small" v-model="childForm.legalIdCard" :disabled="true" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="企业联系人名称" :label-width="formLabelWidth">
                    <el-input size="small" v-model="childForm.contractName" :disabled="true" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系人手机号码" :label-width="formLabelWidth">
                    <el-input size="small" v-model="childForm.contractMobile" :disabled="true" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="企业银行账号" :label-width="formLabelWidth">
                    <el-input size="small" v-model="childForm.bankAccountNo" :disabled="true" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="开户银行" :label-width="formLabelWidth">
                    <el-input size="small" v-model="childForm.branchName" :disabled="true" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { openAccountDetail } from '@/request/api'
export default {
    props: {
        dialogEnterpriseDetailFormVisible: { type: Boolean, default: false },
        id: { type: Number, default: null}
    },
    
    data() {
        return {
            formLabelWidth: '140px',
            childForm: {}
        }
    },
    watch: {
        id(newVue) {
            this.queryDetail(newVue)
        }
    },
    methods: {
        async queryDetail(newVue) {
            try {
                const p = { id: newVue }
                const res = await openAccountDetail(p)
                if (res.status === 200 && res.data) {
                    const resData = res.data
                    this.childForm = {...resData}
                    this.childForm.shortAddress = resData.province + " " + resData.city + " " + resData.district
                } else {
                    this.$message.error(res.message)
                }
            } catch (error) {
                console.log(error);
            }
        },
        close() {
            this.$emit('update:dialogEnterpriseDetailFormVisible', false)
        }
    }
}
</script>

<style lang="stylus" scoped>
.el-autocomplete {
    width: 100%;
}
.dislog_header {
    position: relative;
    height: 80px;
}
.account_state {
    position: absolute;
    right: 0;
    bottom: 30px;
}
.fail-reason {
    position: absolute;
    right: 0;
    bottom: 0;
}
/deep/.el-dialog__body {
    padding-top: 10px;
}

</style>

